<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-11-17 08:01:15
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 13:03:21
 * @ Description: Component displaying job applicant cards for companies.
 -->

<template>
    <v-card
        height="235px"
        width="100%"
        max-width="280px"
        class="rounded-lg pa-3 mx-auto"
    >
        <v-row
            align="center"
            style="position: relative;"
        >
            <div
                style="position: absolute; left: 12px; top: 12px;"
                :style="applicant.applicant_declined ? 'opacity: 0.3;' : ''"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Status:
                </div>
                <div
                    class="os-12-sb"
                    :style="applicant.applicant_declined ? 'color: #E80620;' : 'color: #FFA858;'"
                >
                    <span v-if="applicant.applicant_declined">Declined</span>
                    <span v-else>Pending</span>
                </div>
            </div>
            <v-col
                cols="12"
                class="text-center"
            >
                <v-avatar
                    height="65px"
                    width="65px"
                    style="cursor: pointer;"
                    @click="viewApplicant"
                >
                    <v-img
                        :src="applicant.applicant_avatar ? applicant.applicant_avatar : '/general/FixerIndividualIcon.png'"
                        contain
                    />
                </v-avatar>
            </v-col>
            <v-col
                cols="12"
                class="os-16-sb text-truncate text-center pt-0"
                style="height: 30px; max-height: 30px; display: flex; align-items: center; justify-content: center;"
            >
                {{ applicant.applicant_first_name }} {{ applicant.applicant_last_name }}
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="7"
                class="px-2"
                :style="applicant.applicant_declined ? 'opacity: 0.3;' : ''"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Application Date:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A;"
                >
                    {{ applicant.applicant_created_date }}
                </div>
            </v-col>
            <v-col
                cols="5"
                class="text-right os-12-sb px-2"
                style="color: #FFA858; position: relative;"
                align-self="end"
                :style="applicant.applicant_declined ? 'opacity: 0.3;' : ''"
            >
                {{ applicant.owner_unread_messages }} Message<span v-if="applicant.owner_unread_messages !== 1">s</span>
                <!-- {{ applicant.messageCount }} Message<span v-if="applicant.messageCount !== 1">s</span> -->
                <v-icon
                    style="position: absolute; top: -8px; right: 8px;"
                    color="#FFA858"
                    v-if="applicant.owner_unread_messages === 0"
                >
                    {{ mdiMessageProcessingOutline }}
                </v-icon>
                <v-icon
                    style="position: absolute; top: -8px; right: 8px;"
                    color="#FFA858"
                    v-else
                >
                    {{ mdiMessageProcessing }}
                </v-icon>
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                class="text-center"
            >
                <!-- Bottom Buttons (Not Declined) -->
                <span
                    v-if="!applicant.applicant_declined"
                >
                    <v-row>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0;"
                        >
                            <v-btn
                                icon
                                @click="confirmToggleScheduleInterview"
                            >
                                <v-icon
                                    color="#2E567A"
                                    size="20"
                                >
                                    {{ mdiCalendar }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Interview
                            </span>
                        </v-col>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0;"
                        >
                            <v-btn
                                icon
                                @click="toggleApplicantFavourite"
                            >
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-if="applicant.applicant_favourite"
                                >
                                    {{ mdiHeart }}
                                </v-icon>
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-else
                                >
                                    {{ mdiHeartOutline }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Favourite
                            </span>
                        </v-col>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0;"
                        >
                            <v-btn
                                icon
                                @click="confirmApplicantAssign"
                            >
                                <v-icon
                                    color="#069B34"
                                    size="20"
                                >
                                    {{ mdiCheckCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Assign
                            </span>
                        </v-col>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0; "
                        >
                            <v-btn
                                icon
                                @click="confirmToggleApplicantDecline"
                            >
                                <v-icon
                                    color="#E80620"
                                    size="20"
                                >
                                    {{ mdiClose }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Decline
                            </span>
                        </v-col>
                    </v-row>
                </span>
                <!-- Bottom Buttons (Not Declined) -->

                <!-- Bottom Buttons (Declined) -->
                <span
                    v-else
                >
                    <v-row>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0; opacity: 0.3;"
                        >
                            <v-btn
                                icon
                                disabled
                            >
                                <v-icon
                                    color="#2E567A"
                                    size="20"
                                >
                                    {{ mdiCalendar }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Interview
                            </span>
                        </v-col>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0; opacity: 0.3;"
                        >
                            <v-btn
                                icon
                                disabled
                            >
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-if="applicant.applicant_favourite"
                                >
                                    {{ mdiHeart }}
                                </v-icon>
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-else
                                >
                                    {{ mdiHeartOutline }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Favourite
                            </span>
                        </v-col>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0; opacity: 0.3;"
                        >
                            <v-btn
                                icon
                                disabled
                            >
                                <v-icon
                                    color="#069B34"
                                    size="20"
                                >
                                    {{ mdiCheckCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Assign
                            </span>
                        </v-col>
                        <v-col
                            class="text-center pa-0"
                            style="line-height: 0;"
                        >
                            <v-btn
                                icon
                                @click="confirmToggleApplicantDecline"
                            >
                                <v-icon
                                    color="#44D7B6"
                                    size="20"
                                >
                                    {{ mdiPlus }}
                                </v-icon>
                            </v-btn>
                            <br />
                            <span
                                class="os-12-sb"
                                style="color: #2E567A;"
                            >
                                Restore
                            </span>
                        </v-col>
                    </v-row>
                </span>
                <!-- Bottom Buttons (Declined) -->
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
    import { mdiMessageProcessingOutline, mdiMessageProcessing, mdiCalendar, mdiHeart, mdiHeartOutline, mdiCheckCircleOutline, mdiClose, mdiPlus } from '@mdi/js'

    export default {
        name: 'WorkJobCompanyApplicantCardComponent',

        props: {
            applicant: {
                type: Object,
                required: true
            },

            item: {
                type: Object,
                required: true
            }
        },

        data () {
            return {
                mdiMessageProcessingOutline,
                mdiMessageProcessing,
                mdiCalendar,
                mdiHeart,
                mdiHeartOutline,
                mdiCheckCircleOutline,
                mdiClose,
                mdiPlus
            }
        },

        methods: {
            viewApplicant () {
                this.$emit('viewApplicant', this.item, this.applicant)
            },

            confirmToggleScheduleInterview () {
                this.$emit('confirmToggleScheduleInterview', this.applicant, this.item)
            },

            toggleApplicantFavourite () {
                this.$emit('toggleApplicantFavourite', this.applicant, this.item)
            },

            confirmToggleApplicantDecline () {
                this.$emit('confirmToggleApplicantDecline', this.applicant, this.item)
            },

            confirmApplicantAssign () {
                this.$emit('confirmApplicantAssign', this.applicant, this.item)
            }
        }
    }
</script>