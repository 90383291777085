<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-25 15:44:31
 * @ Description: Fixer's job applicants dashboard page in the user's job dashboard section of the app.
 -->

<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
                :style="$vuetify.breakpoint.smAndDown ? 'padding-top: 60px;' : ''"
            >
                <!-- Search Job Field -->
                <v-text-field
                    dense
                    hide-details
                    outlined
                    color="#2E567A"
                    placeholder="Search..."
                    class="os-13-r"
                    background-color="white"
                    :style="$vuetify.breakpoint.smAndDown ? 'width: calc(100% - 94px); position: absolute; top: 12px; min-width: 0px;' : $vuetify.breakpoint.md ? 'width: 195px; position: absolute; right: 200px; top: -40px; min-width: 0px;' : 'width: 235px; position: absolute; right: 200px; top: -40px; min-width: 0px;'"
                    v-model="mainSearch"
                    clearable
                    @click:clear="clearMainSearch"
                >
                </v-text-field>
                <!-- Search Job Field -->

                <!-- Search Job Button -->
                <v-btn
                    class="white--text no-uppercase rounded-r-lg os-13-sb"
                    color="#FFA858"
                    :style="$vuetify.breakpoint.smAndDown ? 'position: absolute; top: 10px; right: 16px;' : 'position: absolute; top: -42px; right: 132px;'"
                    @click="filterJobs"
                >
                    Search
                </v-btn>
                <!-- Search Job Button -->

                <!-- Card View -->
                <v-row
                    v-if="selectedView === 'card'"
                >
                    <!-- No Jobs with Applicants -->
                    <v-col
                        v-if="items === null || items.length === 0 || !hasApplicants"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No applicants
                    </v-col>
                    <!-- No Jobs with Applicants -->

                    <!-- Jobs with Applicants -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-for="(item, index) in items"
                        :key="index"
                        v-else
                    >
                        <!-- Job Detail -->
                        <v-row
                            style="background-color: white; min-height: 89px;"
                            class="mt-8 mb-4 mx-1"
                            align="center"
                            justify="center"
                            v-if="item.applicant.length > 0"
                        >
                            <v-col
                                class="flex-shrink-1 flex-grow-0"
                            >
                                <div
                                    style="height: 65px; align-items: center; justify-content-center;"
                                >
                                    <v-avatar
                                        height="65px"
                                        width="65px"
                                        class="ml-3"
                                    >
                                        <v-img
                                            :src="item.company_avatar ? item.company_avatar : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </div>
                            </v-col>
                            <v-col
                                class="flex-shrink-0 flex-grow-1"
                            >
                                <v-row
                                    align="center"
                                >
                                    <v-col
                                        class="flex-shrink-0 flex-grow-1"
                                        style="line-height: 1;"
                                    >
                                        <div
                                            class="os-17-sb px-3"
                                            style="display: flex; align-items: center;"
                                        >
                                            <div>
                                                <span>{{ item.job_name }}</span>
                                                <br />
                                                <span class="os-12-b" style="color: #2E567A;">
                                                    <span v-if="item.job_budget && item.job_budget_symbol">{{ item.job_budget_symbol }}{{ item.job_budget }}</span>
                                                    <span v-else>No budget</span>
                                                </span>
                                                <br v-if="$vuetify.breakpoint.xs" />
                                                <span class="os-12-sb ml-sm-3" style="color: #2E567A;">
                                                    {{ item.job_mode_name }}
                                                </span>
                                                <v-icon
                                                    size="20"
                                                    color="#2E567A"
                                                >
                                                    {{ mdiMapMarkerOutline }}
                                                </v-icon>
                                            </div>

                                            <!-- Desktop Applicant Search -->
                                            <div
                                                style="width: 275px; position: absolute; right: 24px;"
                                                v-if="$vuetify.breakpoint.mdAndUp"
                                            >
                                                <v-text-field
                                                    dense
                                                    hide-details
                                                    outlined
                                                    color="#2E567A"
                                                    placeholder="Search..."
                                                    class="os-13-r rounded-lg"
                                                    background-color="white"
                                                    style="width: 275px;"
                                                    v-model="item.applicantSearch"
                                                    clearable
                                                    @click:clear="item.applicantSearch = ''"
                                                    @input="filterApplicants(item)"
                                                >
                                                    <template v-slot:append>
                                                        <v-icon
                                                            color="#C5CEE0"
                                                            class="ma-0"
                                                        >
                                                            {{ mdiMagnify }}
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                            <!-- Desktop Applicant Search -->
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- Mobile Applicant Search -->
                            <v-col
                                cols="12"
                                v-if="$vuetify.breakpoint.smAndDown"
                                class="text-center"
                                style="max-width: 300px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r rounded-lg"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="item.applicantSearch"
                                    clearable
                                    @click:clear="item.applicantSearch = ''"
                                    @input="filterApplicants(item)"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <!-- Mobile Applicant Search -->
                        </v-row>
                        <!-- Job Detail -->

                        <!-- Job Applicants -->
                        <v-row
                            v-if="item.filteredApplicants.length > 0"
                        >
                            <v-col
                                cols="12"
                                md="4"
                                lg="3"
                                v-for="(applicant, index) in item.filteredApplicants"
                                class="pa-lg-1 pa-xl-3"
                                :key="index"
                            >
                                <work-job-company-applicant-card-component
                                    :item="item"
                                    :applicant="applicant"
                                    @viewApplicant="viewApplicant"
                                    @confirmToggleScheduleInterview="confirmToggleScheduleInterview"
                                    @toggleApplicantFavourite="toggleApplicantFavourite"
                                    @confirmToggleApplicantDecline="confirmToggleApplicantDecline"
                                    @confirmApplicantAssign="confirmApplicantAssign"
                                />
                            </v-col>
                        </v-row>
                        <!-- Job Applicants -->

                        <!-- No Applicants in Applicant Search -->
                        <v-row
                            v-else-if="item.filteredApplicants.length === 0 && item.applicant.length > 0"
                        >
                            <v-col
                                cols="12"
                                class="os-12-r text-center"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                {{ noResultsText(item.applicantSearch) }}
                            </v-col>
                        </v-row>
                        <!-- No Applicants in Applicant Search -->
                    </v-col>
                    <!-- Jobs with Applicants -->
                </v-row>
                <!-- Card View -->

                <!-- List View -->
                <v-row
                    v-if="selectedView === 'list'"
                >
                    <!-- No Jobs with Applicants -->
                    <v-col
                        v-if="items === null || items.length === 0 || !hasApplicants"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No applicants
                    </v-col>
                    <!-- No Jobs with Applicants -->

                    <!-- Jobs with Applicants -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-for="(mainItem, index) in items"
                        :key="index"
                        v-else
                    >
                        <v-row
                            class="pt-8"
                        >
                            <!-- Job Detail -->
                            <v-col
                                class="flex-shrink-1 flex-grow-0 pr-0 ml-3"
                                style="background-color: white;"
                                v-if="mainItem.applicant.length > 0"
                            >
                                <div
                                    style="height: 65px; align-items: center;"
                                >
                                    <v-avatar
                                        height="65px"
                                        width="65px"
                                        class="ml-3"
                                    >
                                        <v-img
                                            :src="mainItem.company_avatar ? mainItem.company_avatar : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </div>
                            </v-col>
                            <v-col
                                class="pl-0 flex-grow-1 flex-shrink-0 mr-3"
                                style="background-color: white; line-height: 1; height: 89px; display: flex; position: realtive;"
                                v-if="mainItem.applicant.length > 0"
                            >
                                <div
                                    class="os-17-sb px-3"
                                    style="display: flex; align-items: center;"
                                >
                                    <div>
                                        <span>{{ mainItem.job_name }}</span>
                                        <br />
                                        <span class="os-12-b" style="color: #2E567A;">
                                            <span v-if="mainItem.job_budget && mainItem.job_budget_symbol">{{ mainItem.job_budget_symbol }}{{ mainItem.job_budget }}</span>
                                            <span v-else>No budget</span>
                                        </span>
                                        <br v-if="$vuetify.breakpoint.xs" />
                                        <span class="os-12-sb ml-sm-3" style="color: #2E567A;">
                                            {{ mainItem.job_mode_name }}
                                        </span>
                                        <v-icon
                                            size="20"
                                            color="#2E567A"
                                        >
                                            {{ mdiMapMarkerOutline }}
                                        </v-icon>
                                    </div>

                                    <div
                                        style="width: 275px; position: absolute; right: 24px;"
                                    >
                                        <v-text-field
                                            dense
                                            hide-details
                                            outlined
                                            color="#2E567A"
                                            placeholder="Search..."
                                            class="os-13-r rounded-lg"
                                            background-color="white"
                                            style="width: 275px;"
                                            v-model="mainItem.applicantSearch"
                                            clearable
                                            @click:clear="mainItem.applicantSearch = ''"
                                            @input="filterApplicants(mainItem)"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    color="#C5CEE0"
                                                    class="ma-0"
                                                >
                                                    {{ mdiMagnify }}
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                            </v-col>
                            <!-- Job Detail -->

                            <!-- Applicants -->
                            <v-col
                                cols="12"
                                class="pt-0 px-md-1 px-lg-3"
                                v-if="mainItem.filteredApplicants.length > 0"
                            >
                                <v-data-table
                                    :headers="tableHeaders"
                                    :items="mainItem.filteredApplicants"
                                    item-key="id"
                                    hide-default-footer
                                    disable-pagination
                                    :no-data-text="noResultsText(mainItem.applicantSearch)"
                                >
                                    <template
                                        v-slot:[`item.name`]="{ item }"
                                    >
                                        <span
                                            :style="item.applicant_declined ? 'opacity: 0.3;' : ''"
                                        >
                                            <v-row
                                                align="center"
                                            >
                                                <v-col
                                                    class="flex-grow-0 flex-shrink-1"
                                                >
                                                    <v-avatar
                                                        height="46px"
                                                        width="46px"
                                                        @click="viewApplicant(mainItem, item)"
                                                        style="cursor: pointer;"
                                                    >
                                                        <v-img
                                                            :src="item.applicant_avatar ? item.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                                            contain
                                                        />
                                                    </v-avatar>
                                                </v-col>
                                                <v-col
                                                    class="flex-grow-1 flex-shrink-0 pa-0"
                                                >
                                                    {{ item.applicant_first_name }} {{ item.applicant_last_name }}
                                                </v-col>
                                            </v-row>
                                        </span>
                                    </template>
                                    <template
                                        v-slot:[`item.applicationDate`]="{ item }"
                                    >
                                        <span
                                            :style="item.applicant_declined ? 'opacity: 0.3;' : ''"
                                        >
                                            {{ item.applicant_created_date }}
                                        </span>
                                    </template>
                                    <template
                                        v-slot:[`item.status`]="{ item }"
                                    >
                                        <span
                                            class="os-13-sb"
                                            :style="item.applicant_declined ? 'color: #E80620;' : 'color: #FFA858;'"
                                        >
                                            <span v-if="item.applicant_declined">Declined</span>
                                            <span v-else>Pending</span>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.owner_unread_messages`]="{ item }">
                                        <span
                                            :style="item.applicant_declined ? 'opacity: 0.3;' : ''"
                                        >
                                            <!-- {{ item.messageCount }} New Message<span v-if="item.messageCount !== 1">s</span> -->
                                            {{ item.owner_unread_messages }} New Message<span v-if="item.owner_unread_messages !== 1">s</span>
                                            <v-badge
                                                overlap
                                                dot
                                                color="#FFA858"
                                                bottom
                                                v-if="item.owner_unread_messages > 0"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    style="opacity: 0.65;"
                                                    size="20"
                                                    class="ml-2"
                                                >
                                                    {{ mdiMessageProcessing }}
                                                </v-icon>
                                            </v-badge>
                                            <v-icon
                                                color="#2E567A"
                                                style="opacity: 0.65;"
                                                size="20"
                                                class="ml-2"
                                                v-else
                                            >
                                                {{ mdiMessageProcessing }}
                                            </v-icon>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <span
                                            v-if="!item.applicant_declined"
                                        >
                                            <v-row
                                                style="max-width: calc(100% - 12px);"
                                            >
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        @click="confirmToggleScheduleInterview(item, mainItem)"
                                                    >
                                                        <v-icon
                                                            color="#2E567A"
                                                            size="20"
                                                        >
                                                            {{ mdiCalendar }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Interview
                                                    </span>
                                                </v-col>
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        @click="toggleApplicantFavourite(item, mainItem)"
                                                    >
                                                        <v-icon
                                                            color="#FFA858"
                                                            size="20"
                                                            v-if="item.applicant_favourite"
                                                        >
                                                            {{ mdiHeart }}
                                                        </v-icon>
                                                        <v-icon
                                                            color="#FFA858"
                                                            size="20"
                                                            v-else
                                                        >
                                                            {{ mdiHeartOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Favourite
                                                    </span>
                                                </v-col>
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        @click="confirmApplicantAssign(item, mainItem)"
                                                    >
                                                        <v-icon
                                                            color="#069B34"
                                                            size="20"
                                                        >
                                                            {{ mdiCheckCircleOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Assign
                                                    </span>
                                                </v-col>
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        @click="confirmToggleApplicantDecline(item, mainItem)"
                                                    >
                                                        <v-icon
                                                            color="#E80620"
                                                            size="20"
                                                        >
                                                            {{ mdiClose }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Decline
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </span>
                                        <span
                                            v-else
                                        >
                                            <v-row
                                                style="max-width: calc(100% - 12px);"
                                            >
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px; opacity: 0.3;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        disabled
                                                    >
                                                        <v-icon
                                                            color="#2E567A"
                                                            size="20"
                                                        >
                                                            {{ mdiCalendar }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Interview
                                                    </span>
                                                </v-col>
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px; opacity: 0.3;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        disabled
                                                    >
                                                        <v-icon
                                                            color="#FFA858"
                                                            size="20"
                                                            v-if="item.applicant_favourite"
                                                        >
                                                            {{ mdiHeart }}
                                                        </v-icon>
                                                        <v-icon
                                                            color="#FFA858"
                                                            size="20"
                                                            v-else
                                                        >
                                                            {{ mdiHeartOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Favourite
                                                    </span>
                                                </v-col>
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px; opacity: 0.3;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        disabled
                                                    >
                                                        <v-icon
                                                            color="#069B34"
                                                            size="20"
                                                        >
                                                            {{ mdiCheckCircleOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Assign
                                                    </span>
                                                </v-col>
                                                <v-col
                                                    class="text-center pa-0"
                                                    style="line-height: 0; max-width: 70px;"
                                                >
                                                    <v-btn
                                                        icon
                                                        class="mt-n3"
                                                        @click="confirmToggleApplicantDecline(item, mainItem)"
                                                    >
                                                        <v-icon
                                                            color="#44D7B6"
                                                            size="20"
                                                        >
                                                            {{ mdiPlus }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <br />
                                                    <span
                                                        class="os-12-sb"
                                                        style="color: #2E567A;"
                                                    >
                                                        Restore
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-col>
                            <!-- Applicants -->

                            <!-- No Applicants in Applicant Search -->
                            <v-col
                                cols="12"
                                class="os-12-r text-center"
                                style="color: rgba(0, 0, 0, 0.38);"
                                v-else-if="mainItem.filteredApplicants.length === 0 && mainItem.applicant.length > 0"
                            >
                                {{ noResultsText(mainItem.applicantSearch) }}
                            </v-col>
                            <!-- No Applicants in Applicant Search -->
                        </v-row>
                    </v-col>
                    <!-- Jobs with Applicants -->
                </v-row>
                <!-- List View -->
            </v-col>
        </v-row>

        <!-- Confirm Toggle Decline Applicant Overlay -->
        <work-general-applicant-confirm-decline-toggle-overlay-component
            :showDeclineToggleOverlayComponent="showDeclineToggleOverlayComponent"
            :applicantToToggle="applicantToToggle"
            @toggleDeclineToggleOverlayComponent="toggleDeclineToggleOverlayComponent"
        />
        <!-- Confirm Toggle Decline Applicant Overlay -->

        <!-- Schedule Interview Overlay -->
        <work-general-applicant-schedule-interview-overlay-component
            :showScheduleInterviewOverlayBoolean="showScheduleInterviewOverlayBoolean"
            :applicantToInterview="applicantToInterview"
            :applicantToInterviewJob="applicantToInterviewJob"
            @toggleScheduleInterviewOverlayComponent="toggleScheduleInterviewOverlayComponent"
        />
        <!-- Schedule Interview Overlay -->

        <!-- Confirm Assign Applicant Overlay -->
        <work-general-applicant-confirm-assign-overlay-component
            :showAssignConfirmationOverlayComponent="showAssignConfirmationOverlayComponent"
            :applicantToAssign="applicantToAssign"
            :applicantToAssignJob="applicantToAssignJob"
            @toggleAssignConfirmationOverlayComponent="toggleAssignConfirmationOverlayComponent"
        />
        <!-- Confirm Assign Applicant Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->
    </div>
</template>
<script>
    import { mdiMagnify, mdiHeartOutline, mdiHeart, mdiMessageProcessingOutline, mdiMessageProcessing, mdiCalendar, mdiCheckCircleOutline, mdiClose, mdiPlus, mdiMapMarkerOutline } from '@mdi/js'
    import { GeneralInvoiceController, WorkJobBoardController, WorkJobApplicationStatusController, GeneralProfileApplicantController, WorkJobApplicationInterviewController } from '@/logic/controller/index.controller.js'
    import WorkGeneralApplicantConfirmDeclineToggleOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantConfirmDeclineToggleOverlayComponent.vue'
    import WorkGeneralApplicantScheduleInterviewOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantScheduleInterviewOverlayComponent.vue'
    import WorkJobCompanyApplicantCardComponent from '@/components/work/job/company/WorkJobCompanyApplicantCardComponent.vue'
    import WorkGeneralApplicantConfirmAssignOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantConfirmAssignOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'

    export default {
        name: 'WorkJobDashboardCompanyApplicantsPage',

        components: {
            WorkGeneralApplicantConfirmDeclineToggleOverlayComponent,
            WorkGeneralApplicantScheduleInterviewOverlayComponent,
            WorkJobCompanyApplicantCardComponent,
            WorkGeneralApplicantConfirmAssignOverlayComponent,
            PaymentOverlayComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        data () {
            return {
                items: [],
                mdiMagnify,
                mdiHeartOutline,
                mdiHeart,
                mdiMessageProcessingOutline,
                mdiMessageProcessing,
                mdiCalendar,
                mdiCheckCircleOutline,
                mdiClose,
                mdiPlus,
                mdiMapMarkerOutline,
                tableHeaders: [
                    {
                        text: 'Applicant',
                        value: 'name'
                    },
                    {
                        text: 'Date Applied',
                        value: 'applicationDate'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    },
                    {
                        text: 'Messages',
                        value: 'owner_unread_messages'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                workJobBoardController: null,
                workJobApplicationStatusController: null,
                generalProfileApplicantController: null,
                workJobApplicationInterviewController: null,
                generalInvoiceController: null,
                mainSearch: '',
                showDeclineToggleOverlayComponent: false,
                applicantToToggle: {},
                applicantToToggleJob: {},
                applicantToInterview: {},
                applicantToInterviewJob: {},
                showScheduleInterviewOverlayBoolean: false,
                applicantToAssign: {},
                applicantToAssignJob: {},
                showAssignConfirmationOverlayComponent: false,
                hasApplicants: false,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false
            }
        },

        beforeMount () {
            this.workJobBoardController = new WorkJobBoardController()
            this.workJobApplicationStatusController = new WorkJobApplicationStatusController()
            this.generalProfileApplicantController = new GeneralProfileApplicantController()
            this.workJobApplicationInterviewController = new WorkJobApplicationInterviewController()
            this.generalInvoiceController = new GeneralInvoiceController()
        },

        async mounted () {
            await this.getApplicants()
        },

        methods: {
            async getApplicants () {
                try {
                    this.items = await this.workJobBoardController.retrieve(null, null, this.mainSearch, 'applicant')
                    this.hasApplicants = false

                    if (this.items && this.items.length > 0) {
                        for (let index = 0; index < this.items.length; index++) {
                            const item = this.items[index]

                            if (item.applicant && item.applicant.length > 0) {
                                for (let index2 = 0; index2 < item.applicant.length; index2++) {
                                    this.hasApplicants = true
                                    const applicant = item.applicant[index2]

                                    if (applicant.applicant_created_date) {
                                        const removeTime = applicant.applicant_created_date.split(' ')[0]
                                        const convertDate = new Date(removeTime)
                                        const splitConvertedDate = convertDate.toString().split(' ')
                                        applicant.applicant_created_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                                    }

                                    applicant.applicant_full_name = applicant.applicant_first_name + ' ' + applicant.applicant_last_name
                                }
                            }

                            item.applicantSearch = ''
                            item.filteredApplicants = item.applicant
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async clearMainSearch () {
                this.mainSearch = ''
                await this.filterJobs()
            },

            async filterJobs () {
                this.items = []
                await this.getApplicants()
            },

            confirmToggleScheduleInterview (applicant, job) {
                this.applicantToInterview = applicant
                this.applicantToInterviewJob = job
                this.toggleScheduleInterviewOverlayComponent(true)
            },

            toggleScheduleInterviewOverlayComponent (value, confirmInterview, interview) {
                this.showScheduleInterviewOverlayBoolean = value

                if (confirmInterview) this.scheduleApplicantInterview(interview)
            },

            confirmApplicantAssign (applicant, job) {
                this.applicantToAssign = applicant
                this.applicantToAssignJob = job
                this.toggleAssignConfirmationOverlayComponent(true)
            },

            toggleAssignConfirmationOverlayComponent (value, confirmApplication) {
                this.showAssignConfirmationOverlayComponent = value

                if (confirmApplication) this.assignApplicant(this.applicantToAssign, this.applicantToAssignJob)
            },

            async assignApplicant (applicant, job) {
                try {
                    await this.workJobApplicationStatusController.assign({ job_id: job.job_id, applicant_id: applicant.applicant_id })

                    this.$store.commit('setSnackbar', {
                        text: 'Assigned successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.$router.push('/job/active')
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    }
                    else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmAssign, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmAssign) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.assignApplicant(this.applicantToAssign, this.applicantToAssignJob)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            viewApplicant (job, applicant) {
                if (this.$route.path !== `/job/${job.job_id}/applicant/${applicant.applicant_id}`) this.$router.push(`/job/${job.job_id}/applicant/${applicant.applicant_id}`)
            },

            filterApplicants (item) {
                const itemIndex = this.items.findIndex(itemToFind => itemToFind.job_id === item.job_id)
                if (itemIndex > -1) {
                    if (item.applicantSearch && item.applicantSearch.replace(/\s/g, '') !== '') {
                        const filteredItems = item.applicant.filter(function (e) {
                            if (e.applicant_full_name) return e.applicant_full_name.toLowerCase().includes(item.applicantSearch.toLowerCase())
                        })

                        this.items[itemIndex].filteredApplicants = filteredItems
                    } else this.items[itemIndex].filteredApplicants = this.items[itemIndex].applicant

                    this.$forceUpdate()
                }
            },

            noResultsText (applicantSearch) {
                return 'No results found for: "' + applicantSearch + '"'
            },

            confirmToggleApplicantDecline (applicant, job) {
                this.applicantToToggle = applicant
                this.applicantToToggleJob = job
                this.toggleDeclineToggleOverlayComponent(true)
            },

            toggleDeclineToggleOverlayComponent (value, confirmApplication) {
                this.showDeclineToggleOverlayComponent = value

                if (confirmApplication) this.toggleApplicantDecline(this.applicantToToggle, this.applicantToToggleJob)
            },

            async toggleApplicantDecline (applicant, job) {
                try {
                    const response = await this.workJobApplicationStatusController.toggleDecline({ job_id: job.job_id, applicant_id: applicant.applicant_id })

                    let action
                    if (response[0].applicant_declined) action = 'Declined'
                    else action = 'Restored'

                    const jobIndex = this.items.findIndex(itemToFind => itemToFind.job_id === job.job_id)
                    if (jobIndex > -1) {
                        const applicantIndex = this.items[jobIndex].applicant.findIndex(itemToFind => itemToFind.applicant_id === applicant.applicant_id)
                        if (applicantIndex > -1) {
                            this.items[jobIndex].applicant[applicantIndex].applicant_declined = response[0].applicant_declined
                        }
                    }

                    this.$store.commit('setSnackbar', {
                        text: `${action} successfully`,
                        show: true,
                        color: '#069B34'
                    })

                    this.applicantToToggle = {}
                    this.applicantToToggleJob = {}
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async toggleApplicantFavourite (applicant, job) {
                try {
                    const response = await this.generalProfileApplicantController.toggleFavourite({ owner_id: job.owner_id, applicant_id: applicant.applicant_id })

                    let action
                    if (response.applicant_favourite) action = 'Added to'
                    else action = 'Removed from'

                    const jobIndex = this.items.findIndex(itemToFind => itemToFind.job_id === job.job_id)
                    if (jobIndex > -1) {
                        const applicantIndex = this.items[jobIndex].applicant.findIndex(itemToFind => itemToFind.applicant_id === applicant.applicant_id)
                        if (applicantIndex > -1) {
                            this.items[jobIndex].applicant[applicantIndex].applicant_favourite = response.applicant_favourite
                        }
                    }

                    this.$store.commit('setSnackbar', {
                        text: `${action} favourites`,
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async scheduleApplicantInterview (interview) {
                try {
                    const dayjs = require('dayjs')

                    interview.start_date_time = dayjs(interview.start_date_time).format()
                    interview.end_date_time = dayjs(interview.end_date_time).format()

                    await this.workJobApplicationInterviewController.create(interview)

                    this.$store.commit('setSnackbar', {
                        text: 'Interview scheduled successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 70px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
